@import "../../../../public/assets/scss/base/variables";

.blog-page-wrap{
    background-color: #f8f8f8;
}
.blog-list-wrap {
    .thumbnail{
        position: relative;

        .posted-on {
            bottom: 40px!important;
        }
    }
    .content{
        padding: 40px;
        @media (max-width: 992px) {
            padding: 30px;
        }
    }
    .title {
        margin: 0 0 1.2em;
        letter-spacing: -.03em;
        word-spacing: .15em;
    }
    .entry-meta{
        margin-top: 60px;
    }
}


.blog-item {
    margin-bottom: 30px;
    .thumbnail{
        position: relative;
        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            background: -webkit-linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%);
            background: -o-linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%);
            background: linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%);
        }
        .posted-on {
            position: absolute;
            bottom: 40px;
            color: #fff;
            display: block;
            z-index: 2;
            left: 40px;
        }
    }
    .posted-on {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: .18em;
    }
    .content{
        padding: 40px;
        background-color: #fff;
        @media (max-width: 992px) {
            padding: 30px;
        }
    }
    .title {
        a{
            color: #000;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            &:hover {
                color: $primary;

                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }
}

.entry-meta {
    font-size: 11px;
    line-height: 1.667;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
    a {
        color: black;

        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
            color: $primary;
        }
    }
}

.entry-meta-inner > span:not(:last-child):after {
    content: "/";
    display: inline-block;
    padding: 0 5px;
}

.single-blog-wrap {
    .entry-content {
        margin-left: auto;
        margin-right: auto;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        img{
            margin-bottom: 30px;
            max-width: 100%;
        }
    }

    .entry-meta {
        margin-bottom: 30px;
        padding: 27px 0 22px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        > * {
            display: inline-block;
        }
        .post-date:after {
            content: "/";
            display: inline-block;
            padding: 0 10px;
        }
    }
    .social-share{
        a {
            color: #999999;
            font-size: 14px;
            display: inline-block;
            margin: 0 0.4rem;
        }
        &:before {
            content: "/";
            display: inline-block;
            padding: 0 10px;
        }
    }
    .entry-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 0;
        font-size: 11px;
        margin-bottom: 60px;
        margin-top: 50px;
        text-transform: uppercase;
        font-weight: 600;
        border-top: 1px solid #e7e7e7;

    }
    .navigation {
          margin-left: auto;
        .nav-link {
            padding: 0;
            margin-left: 2px;
            background-color: $primary;
            font-size: 18px;
            color: #ffffff;
            line-height: 42px;
            height: 44px;
            width: 60px;
            text-align: center;
            transition: background-color 0.3s ease;
            &:hover {
                background-color: darken($primary, 15%);
                color: #fff;
                transition: background-color 0.3s ease;
            }
        }
    }
}