@import "../../../../public/assets/scss/base/variables";
.the-building-wrap{
    .text-description{
        padding: 0 16%;
        [class*="col-"]:first-child{
            border-right: 1px solid $border-color;
        }
        @media (max-width: 992px) {
            padding:0 15px;
        }
    }
    .box-counter{
        position: relative;
        &:before{
            content: "";
            background: $primary;
            height: 3px;
            width: 100px;
            right: 0;
            top: 50%;
            position: absolute;
            right: -20%;
            @media (max-width: 992px) {
                content: none;
            }
        }
    }
    .counter-wrap{
        [class*="col-"]:last-child{
            .box-counter {
                &:before {
                    content: none;
                }
            }
        }
    }
}
.gallerry-wrap{
    padding: 0px 60px 60px 60px;
    @media (max-width: 992px) {
        padding:0 30px;
        margin-bottom: 30px;
    }
    @media (min-width: 992px) {
        [class*="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .gallerry-image{
        position: relative;
        overflow: hidden;
        display: block;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        @media (max-width: 992px) {
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 20px;
            }
        }
        img{
            display: block;
            transition: all 0.6s ease;
            transform-origin: bottom right;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
            .gallery-item-overlay{
                opacity: 1;
                background: rgba(202,190,159,0.8);
            }
        }
        .gallery-item-overlay{
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            opacity: 0;
            color: #fff;
            top: 0;
            bottom: 0;
            transition: all 0.6s ease;
            i{
                color: $white;
                font-size: 25px;
                z-index: 1;
                opacity: 1;
            }
        }
    }
}