@import "../../../../public/assets/scss/base/variables";


.btn {
    text-transform: uppercase;
    letter-spacing: $input-btn-letter-spacing;
    span,
    i{
        margin-left: 13px;
    }
}