.site-header {
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.05);
}

.site-header-wrap {
    padding: 20px;
    flex-wrap: wrap;
    @include media-breakpoint-down(lg) {
       padding: 20px 15px;
    }
}


.site-branding,
.header-group {
    width: 19%;
    @include media-breakpoint-down(md) {
        width: 45%;
    }
}

.site-branding {
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 100%;
        text-align: center;
    }
}



.header-group {
    justify-content: flex-end;
    @media (min-width: 992px) and (max-width: 1199px) {
       width: 25%;
    }
    button.btn{
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.mainmenu-container {
    clear: both;

    > ul {
        display: flex;
        align-items: center;
        justify-content: center;

        > li {
            &:not(:last-child) {
                margin-right: 25px;
                @include media-breakpoint-up(lg) {
                    margin-right: 40px;
                }
                @media (min-width: 1366px) {
                    margin-right: 55px;
                }
            }

            > a {
                padding-top: 16px;
                padding-bottom: 16px;
                font-weight: 600;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 2px ;
                color: $black4;
                &:after {
                    content: '';
                    bottom: 0;
                    width: 0;
                    height: 3px;
                    background-color: $primary;
                    position: absolute;
                    z-index: 2;
                    left: 0;

                    -webkit-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;
                    -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
                    -o-transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
                    transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
                }
                &.selected,
                &:hover {
                    color: $primary;
                    &:after {
                        width: 40px;
                    }
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;
    }

    ul ul {
        background-color: $black1;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        position: absolute;
        top: 100%;
        display: none;
        z-index: 9;
        min-width: 270px;
        padding: 20px 0;

        a {
            padding: 5px 20px;
            color: rgba(255, 255, 255, 0.65);
            font-size: 13px;
            display: block;

            &:hover {
                color: $white1;
            }
        }

        ul {
            top: 0;
            left: 100%;
        }
    }

    li {
        position: relative;

        &:hover {
            > ul {
                display: block;
            }
        }
    }

    a {
        display: block;
        text-decoration: unset;

        i {
            padding-left: 10px;
        }
    }
}

.menu-toggle {
    display: none;
}

.navigation-top {
    padding: 5px 30px 5px 30px;
    flex: 1;
    @media  (max-width: 1199px){
        padding-left: 0;
        padding-right: 20px;
        .mainmenu-container > ul {
            justify-content: flex-start;
        }
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.site-header {
    .container-fluid {
        max-width: 1820px;
    }
}

.site-branding {
    img {

    }
}

.header-group {
    a {
        color: $black1;
    }
    .header-button {
        color: $black1;
        min-width: 30px;
        min-height: 30px;
        line-height: 30px;
        text-align: center;
        display: block;
        position: relative;

        i {
            font-size: 18px;
        }
    }

    > div:not(:last-child) {
        margin-right: 10px;
    }

    .count {
        font-size: 11px;
        line-height: 1;
        white-space: nowrap;
        vertical-align: super;
        color: $primary;
        position: absolute;
        top: 5px;
        right: -3px;
    }
}

.site-header-cart {
    position: relative;

    .shopping_cart {
        background-color: #fff;
        width: 270px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.17);
        font-size: 14px;
        padding: 0;
        list-style: none;

        .empty-message {
            padding: 30px;
            margin: 0;
            min-height: 180px;
            text-align: center;
            overflow: hidden;
            line-height: 26px;

            &:before {
                content: '+';
                font-size: 100px;
                display: block;
                transform: rotate(45deg);
                line-height: 70px;
                padding: 10px;
                color: $primary;
            }
        }
    }
}

.shopping_cart_inner {
    padding: 0;
    list-style: none;
    margin: 0;

    > li {
        width: 100%;
        border-width: 0 0 1px 0;
        padding: 15px 20px;
        border-bottom: 1px solid #dbdbdb;
    }

    .total {
        text-align: right;

        span {
            vertical-align: middle;
            padding-left: 0.5rem;
            font-weight: 600;
        }
    }

    .remove {
        position: absolute;
        font-size: 18px;
        color: #bbbbbb !important;
        cursor: pointer;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .cart-quantity {
        color: #888888;
        font-weight: 400;
        font-size: 13px;
        border: none;
    }

    .title {
        font-size: 13px;
        line-height: 1.5;
        display: block;
        font-weight: 400;
    }

    .product-item {
        display: flex;
        align-items: center;
        position: relative;

        img {
            width: 40px;
            height: auto;
        }
    }

    .buttons {
        .view-cart,
        .checkout {
            display: block;
            text-align: center;
            text-transform: uppercase;
            padding: 0.6rem 1.25rem;
            font-size: 12px;
            line-height: 1.6;
            font-family: $typo-headings-font-family;
            font-weight: 500;
            &:hover {
                opacity: 0.7;
            }
        }
        .view-cart {
            background-color: #f5f5f5;
            margin-bottom: 10px;
        }
        .checkout {
            background-color: $primary;
            color: $white1;
        }
    }
}

.header-group .header-button.rpm-trigger {
    @include media-breakpoint-up(lg) {
        display: none;
        font-size: 16px;
    }
}

.popup-search {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    .closebtn {
        position: fixed;
        top: 20px;
        right: 35px;
        color: #fff;
        font-size: 40px;
        cursor: pointer;
    }
    .search-input {
        border-color: #ffffff;
        border-width: 0 0 2px 0;
        width: 100%;
        background: transparent;
        text-align: center;
        font-size: 32px;
        color: $white1;
        padding: 20px;
    }

    .products-live-search {
        width: 100%;
        margin-top: 20px;
        .product-block-list {
            margin-bottom: 30px;
            .inner {
                background-color: #fff;
                padding: 15px;
            }
        }
    }
}

.account-dropdown {
    text-align: left;
    a {
        &.register-link, &.lostpass-link {
            color: $primary;
            @include font-size(14);
            &:hover {
                text-decoration: underline;
            }
        }
    }
    label {
        margin-bottom: 0;
        @include font-size(14);
        line-height: 1.7;
        color: #000;
    }
    p {
        margin-bottom: 0.75rem;
    }
    input,button{
        line-height: normal !important;

    }
    input {
        font-size: 14px;
        border-width: 1px;
        border-color: #e1e1e1;
        border-style: solid;
        display: block;
        width: 100%;
        padding: .8rem 1rem;
        margin-top: 5px;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
    }
    .login-form-title {
        color: #000;
        font-weight: 600;
    }
    .login-form-head {
        border-bottom: 1px solid #000;
        padding-bottom: 15px;
    }
    .account-wrap {
        position: relative;
        background: $white1;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.17);
    }
    .account-inner {
        width: 320px;
        padding: 17px 20px;
        max-height: 500px;
        overflow: auto;
        &.dashboard {
            width: 210px;
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #000000;
        }
    }
}

.dropdown-parent {
    position: relative;
    .group-dropdown {
        right: 0;
        top: calc(100% + 15px);
        position: absolute;
        transition: all ease-in-out 0.2s;
        pointer-events: none;
        opacity: 0;
        z-index: 99;
        visibility: hidden;
        padding-top: 10px;
    }
    &:hover {
        .group-dropdown {
            top: 100%;
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}