@import "../../../../public/assets/scss/base/variables";
ul.apartment-items{
    padding: 0;
    margin-bottom: 50px;
    li{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 10px 0;
        border-bottom: 1px solid $border-color;
        font-size: 13px;
        cursor: pointer;
        .value-list{
            font-weight: 500;
            color: $headings-color;
        }
    }
}

.react-tabs{
    p{
        color: $headings-color;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.4px;
    }
    .react-tabs__tab-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        li{
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 18px;
            letter-spacing: 2.4px;
            color: #3f4448;
            background-color: #f8f8f8;
            text-align: center;
            font-family: $font-family-base;
            margin: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 20px 25px;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            -ms-flex: 1 !important;
            flex: 1 !important;
            &:hover,&.react-tabs__tab--selected{
                background-color: $primary;
                color: $white;
                position: relative;
                &:after{
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    bottom: -6px;
                    left: 50%;
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    border-top: 6px solid;
                    border-left: 8px solid transparent !important;
                    border-right: 8px solid transparent !important;
                    border-bottom: 0;
                    border-top-color:$primary;
                    @media (max-width: 767px) {
                        content: none;
                    }
                }
            }
            &:after{
                background-color: transparent;
                content: none;
            }
        }
    }
    .row{
        align-items: center;
    }
    .apartment-img{
        padding-left: 80px;
        @media (max-width: 992px) {
            padding-left: 0 !important;
            padding-top: 30px;
        }
    }
    .flex-row-reverse{
        .apartment-img{
            padding-right: 80px;
            padding-left: 0;
            @media (max-width: 992px) {
                padding-right: 0px !important;
            }
        }
    }
}